@use "../config/" as *;
$amp-base-space: 4px;
html {
  &:root {
    font-size: 100%;
    @for $i from 1 through $max-space-count {
      --space-#{$i}: #{$amp-base-space * $i};
      --space-#{$i}-neg: #{$amp-base-space * $i * -1};
    }

    body {
      font-size: 100%;
    }
  }

  a {
    @extend %primary-700;

    &:hover {
      text-decoration: underline;
    }
  }
}

amp-story-page {
  &[active] {
    .webstory-image-layer {
      amp-img.image {
        > img {
          animation: image-zoom 5000ms linear forwards;
        }
      }
    }
  }
}

//Custom style for next/previous/replay buttons. Not recommended. Hence this code has been commented.

// amp-story {
//   div {
//     &.next-container,
//     &.prev-container {
//       button {
//         @extend %flex-c-c;

//         &::before {
//           position: absolute;
//           content: "";
//           width: 100%;
//           height: 100%;
//           @extend %circle-radius;
//           @extend %primary-300-bg;
//         }

//         &::after {
//           position: relative;
//           content: "";
//           width: 100%;
//           height: 100%;
//         }
//       }
//     }

//     &.prev-container {
//       button {
//         &::after {
//           position: relative;
//           rotate: 180deg;
//           background: url("/static-assets/images/svg/arrow-circle-left.svg?v=#{$image-version}")
//             center / 90% no-repeat;
//         }
//       }
//     }

//     &.next-container {
//       button {
//         &::after {
//           position: relative;
//           background: url("/static-assets/images/svg/arrow-circle-right.svg?v=#{$image-version}")
//             center / 90% no-repeat;
//         }
//       }
//     }
//   }

//   amp-story-page {
//     &:last-of-type {
//       &[active] {
//         ~ div.next-container {
//           button {
//             &::after {
//               background: url("/static-assets/images/svg/arrow-circle-replay.svg?v=#{$image-version}")
//                 center / 70% no-repeat;
//             }
//           }
//         }
//       }
//     }
//   }
// }

.waf-webstory {
  .webstory {
    &-media-layer {
      @extend %secondary-1000-bg;

      > amp-img,
      > amp-video {
        mask: linear-gradient(
          to bottom,
          clr(secondary-800, 10) 25%,
          clr(secondary-800, 5) 75%,
          clr(secondary-800, 0) 100%
        );
      }

      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        bottom: 0;
        background: linear-gradient(
          to top,
          clr(secondary-800, 10) 25%,
          clr(secondary-800, 1) 50%,
          clr(secondary-800, 0) 100%
        );
      }
    }

    &-wrapper {
      position: absolute;
      width: 100%;
      height: max-content;
      left: 0;
      bottom: 0;
      @extend %pt-17;
      @extend %pb-8;
      @extend %px-8;
    }

    &.webstory-has-outlink {
      .webstory-wrapper {
        @extend %pb-22;
      }
    }

    &.webstory-hide-content {
      .webstory {
        &-wrapper {
          visibility: hidden;
        }

        &-content-layer {
          &::before {
            @extend %d-none;
          }
        }
      }
    }

    &-cover {
      .webstory {
        &-publisher {
          @extend %mb-3;
          @extend %flex-fs-c;
        }
      }
    }

    .webstory-content {
      gap: var(--space-3);
      @extend %flex-column-n-n;
      .title {
        // font-size: 2.2vh;
        // line-height: 1.5;
        @include line-clamp(3, 2.2vh, 1.5);
        @extend %neutral-50;
        @extend %font-18-pb;
      }

      .description {
        // font-size: 1.8vh;
        // line-height: 1.5;
        @include line-clamp(6, 1.8vh, 1.5);
        @extend %neutral-100;
        @extend %font-10-pr;

        > * {
          // font-size: 1.8vh;
          // line-height: 1.5;
          @include line-clamp(6, 1.8vh, 1.5);
        }
      }

      .tooltip {
        width: max-content;
        height: max-content;
        max-width: 100%;
        // font-size: 1.5vh;
        // line-height: 1;
        border: 0.1rem solid var(--neutral-50);
        @include line-clamp(1, 1.5vh, 1);
        @extend %px-2;
        @extend %py-1;
        @extend %neutral-50;
        @extend %font-12-pb;
        @extend %half-radius;

        a {
          // font-size: 1.5vh;
          @include line-clamp(1, 1.5vh, 1);
          @extend %neutral-50;
          @extend %font-12-pb;
        }
      }

      .metadata {
        @extend %flex-sb-c;
      }

      .meta {
        // font-size: 1.3vh;
        // line-height: 1;
        @include line-clamp(1, 1.3vh, 1);
        @extend %neutral-100;
        @extend %font-10-pr;
        @extend %flex-fs-c;

        .author {
          border-right: 0.1rem solid var(--neutral-100);
          @extend %pr-2;

          &:empty {
            @extend %d-none;
          }

          &:not(:empty) {
            ~ .timeago {
              @extend %pl-2;
            }
          }
        }

        .timeago {
          &:empty {
            @extend %d-none;
          }
        }
      }

      .credits {
        // font-size: 1.3vh;
        // line-height: 1;
        @include line-clamp(1, 1.3vh, 1);
        @extend %neutral-100;
        @extend %font-10-pr;
        @extend %flex-fs-c;
      }
    }
  }
}

//Custom style for next/previous/replay buttons. Not recommended. Hence this code has been commented.

// @media (max-width: 1024px) and (orientation: portrait) {
//   amp-story {
//     div {
//       &.next-container,
//       &.prev-container {
//         button {
//           &::before,
//           &::after {
//             visibility: hidden;
//           }
//         }
//       }
//     }
//   }
// }
